const JobData = [
  {
    id: "1",
    name: "Onsite",
    mainId: "Salesman",
    title: "Salesman",
    postedon: "2024-7-20",
    description:
      " The ability to build strong customer relationships, and a deep understanding of their product or service. They should also be persuasive, adaptable, and highly motivated.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior level",
    salary: "30K to above",
    color: "outdoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Sale Techniques , Negotiating intersections , Managing Time, Safe driving , Resilience , Effective communication , a deep understanding of the product or service being sold, the ability to analyze and interpret data. ",
    ],
    Link: "/jobPlace1",
  },

  {
    id: "2",
    name: "Onsite",
    mainId: "Data Entry",
    title: "Data Entry",
    postedon: "2024-7-19",
    description:
      " A data entry job involves accurately inputting, updating, and maintaining information in digital databases and systems. The role requires a keen eye for detail, proficiency with relevant software, and the ability to manage large volumes of data efficiently. It often includes tasks such as transcribing information from physical documents, verifying data for accuracy, and ensuring data security and confidentiality.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Mid level",
    salary: "35K to above",
    color: "indoor",
    size: "midlevel",
    projectType: "Onsite",
    skills: [
      "Attenion to Detail, Proficiency in Data Entry Software, Typing Speed and Accuracy, Time Management, Basic Computer Skills",
    ],
    Link: "/jobPlace2",
  },
  {
    id: "3",
    name: "Onsite",
    mainId: "Warehouse Supervisor",
    title: "Warehouse Supervisor",
    postedon: "2024-7-31",
    description:
      " A warehouse supervisor oversees daily operations, ensuring efficient storage, inventory management, and distribution of goods. They manage staff, coordinate logistics, and maintain safety standards.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Senior level",
    salary: "45K to above",
    color: "indoor",
    size: "seniorlevel",
    projectType: "Onsite",
    skills: [
      "Leadership and Team Management, Organizational Skills, Inventory Management, Problem-Solving, knowledge of Safety Regulations",
    ],
    Link: "/jobPlace3",
  },
  {
    id: "4",
    name: "Onsite",
    mainId: "Marketing Person",
    title: "Marketing Person",
    postedon: "2024-7-20",
    description:
      " A marketing professional develops and implements strategies to promote products or services, aiming to increase brand awareness and drive sales. They analyze market trends, conduct research, and create targeted campaigns across various channels.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Mid level",
    salary: "30K to above",
    color: "outdoor",
    size: "midlevel",
    projectType: "Onsite",
    skills: [
      "Strategic Planning, Creativity, Analytical Skills, Communication, Digital Maketing Proficincy",
    ],
    Link: "/jobPlace4",
  },
  {
    id: "5",
    name: "Onsite",
    mainId: "Order Taker",
    title: "Order Taker",
    postedon: "2024-8-01",
    description:
      " An order taker processes customer orders, ensuring accuracy and efficiency in recording details and coordinating with the relevant departments. They provide excellent customer service, handle inquiries, and ensure timely order fulfillment.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Fresher",
    salary: "25K to above",
    color: "outdoor",
    size: "fresher",
    projectType: "Onsite",
    skills: [
      "Attenstion to Detail, Customer Service Skills, Communication Skills, Organizational Skills, Proficiency in Order Processing Software.",
    ],
    Link: "/jobPlace5",
  },
  {
    id: "6",
    name: "Onsite",
    mainId: "Specialize in Warehouse",
    title: "Specialize in Warehouse",
    postedon: "2024-7-19",
    description:
      " A warehouse specialist manages inventory, ensures efficient storage and distribution of goods, and maintains accurate records. They handle shipping and receiving, conduct quality checks, and ensure compliance with safety and regulatory standards.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Senior level",
    salary: "50K to above",
    color: "indoor",
    size: "seniorlevel",
    projectType: "Onsite",
    skills: [
      "Inventory Management, Organizational Skills, Attention to Detail, Knowledge of Safety Regulations, Problem-Solving Skills.",
    ],
    Link: "/jobPlace6",
  },
  {
    id: "7",
    name: "Onsite",
    mainId: "Driver",
    title: "Driver",
    postedon: "2024-7-19",
    description:
      " A driver is responsible for transporting goods or passengers safely and efficiently from one location to another. They ensure timely deliveries, maintain the vehicle in good working condition, and adhere to traffic laws and safety regulations.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Fresher",
    salary: "25K to above",
    color: "outdoor",
    size: "fresher",
    projectType: "Onsite",
    skills: [
      "Safe Driving Practices, Time Management, Vehicle, Navigation and Route Planning, Customer Serivce",
    ],
    Link: "/jobPlace7",
  },
  {
    id: "8",
    name: "Onsite",
    mainId: "Store Keeper",
    title: "Store Keeper",
    postedon: "2024-7-19",
    description:
      " A storekeeper manages inventory levels, oversees the receipt and storage of goods, and ensures that products are organized and easily accessible. They handle stock records, manage orders, and maintain a clean and orderly storage environment.",
    location: " Chauburji",
    company: "Career grow",
    jobType: "Full-time",
    experience: "Junior level",
    salary: "35K to above",
    color: "indoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Inventory Management, Organizational Skills, Attention to Detail, Record-Keeping, Basic Accounting Skills.",
    ],
    Link: "/jobPlace8",
  },
  {
    id: "9",
    name: "Onsite",
    mainId: "Gate Keeper",
    title: "Gate Keeper",
    postedon: "2024-7-20",
    description:
      " A gatekeeper controls access to a facility by monitoring entry and exit points, verifying the identity of individuals, and ensuring that security protocols are followed. They manage visitor logs, handle inquiries, and maintain a secure and orderly environment.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Fresher",
    salary: "35K to above",
    color: "indoor",
    size: "fresher",
    projectType: "Onsite",
    skills: [
      "Security Awareness, Communication Skills, Attention to Detail, Customer Service, Problem-Solving Skills",
    ],
    Link: "/jobPlace9",
  },

  {
    id: "10",
    name: "Onsite",
    mainId: "Loader Driver",
    title: "Loader Driver",
    postedon: "2024-7-20",
    description:
      " A loader driver operates vehicles or equipment to load and unload goods efficiently. They ensure safe and accurate handling of cargo, conduct routine vehicle maintenance, and follow safety protocols to prevent accidents and damage during loading and transportation.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior level",
    salary: "35K to above",
    color: "outdoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Vehicle Operation, Load Management, Safety Protocols, Attention to Detail, Basic Mechanical Skills",
    ],
    Link: "/jobPlace10",
  },
  {
    id: "11",
    name: "Onsite",
    mainId: "Guard",
    title: "Guard",
    postedon: "2024-7-20",
    description:
      " A guard is responsible for protecting property and ensuring the safety of people within a designated area. They monitor surveillance equipment, patrol premises, enforce security protocols, and respond to incidents or emergencies as needed.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior level",
    salary: "30K to above",
    color: "indoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Vigilance and Observation, Physical Fitness, Crisis Management, Knowledge of Security Procedures.",
    ],
    Link: "/jobPlace11",
  },
  {
    id: "12",
    name: "Onsite",
    mainId: "Loader/Unloader",
    title: "Loader/Unloader",
    postedon: "2024-7-19",
    description:
      " A loader or unloader driver operates vehicles or equipment to efficiently load and unload cargo from transport vehicles. They ensure that goods are handled safely and accurately, adhere to safety standards, and perform routine checks and maintenance on their equipment.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior level",
    salary: "30K to above",
    color: "outdoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Vehical Operation, Load Handling Safety Compliance, Attention to Detail, Basic Equipment Maintenance.",
    ],
    Link: "/jobPlace12",
  },
  {
    id: "13",
    name: "Onsite",
    mainId: "Supervisor For Marketing",
    title: "Supervisor For Marketing",
    postedon: "2024-7-21",
    description:
      " A marketing supervisor oversees the development and execution of marketing strategies, manages the marketing team, and ensures that campaigns align with business goals. They analyze market trends, track campaign performance, and coordinate with other departments to optimize marketing efforts.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Senior level",
    salary: "50K to above",
    color: "indoor",
    size: "seniorlevel",
    projectType: "Onsite",
    skills: [
      "Leadership and Team Management, Strategic Planning, Analytical Skills, Project Management, Communication Skills.",
    ],
    Link: "/jobPlace13",
  },
  {
    id: "14",
    name: "Onsite",
    mainId: "Accountant",
    title: "Accountant",
    postedon: "2024-7-19",
    description:
      "An accountant manages financial records, prepares financial statements, and ensures compliance with accounting standards and regulations. They analyze financial data, track expenditures, and provide insights for budgeting and financial planning.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Mid level",
    salary: "45K to above",
    color: "indoor",
    size: "midlevel",
    projectType: "Onsite",
    skills: [
      "Financial Reporting, Attention to Detail, Analytical Skills, Knowledge of Accounting Software, Regulatory Compliance.",
    ],
    Link: "/jobPlace14",
  },
  {
    id: "15",
    name: "Onsite",
    mainId: "Machinesman",
    title: "Machinesman",
    postedon: "2024-7-20",
    description:
      " A machineman operates and maintains machinery used in various industrial processes. They ensure machines run efficiently, perform regular maintenance, troubleshoot issues, and adhere to safety protocols to prevent breakdowns and ensure optimal performance.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Senior level",
    salary: "40K to above",
    color: "indoor",
    size: "seniorlevel",
    projectType: "Onsite",
    skills: [
      "Machine Operation, Troubleshooting and Repair, Preventive Maintenance, Technical Knowledge, Safety Compliance.",
    ],
    Link: "/jobPlace15",
  },
  {
    id: "16",
    name: "Onsite",
    mainId: "Machinesman for helper",
    title: "Machinesman for helper",
    postedon: "2024-7-21",
    description:
      " A machinist helper assists in operating and maintaining machinery, performs routine maintenance tasks, and supports machinists by preparing materials and tools. They ensure a clean and organized workspace, follow safety procedures, and help troubleshoot minor issues with equipment.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior level",
    salary: "35K to above",
    color: "indoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Basic Machine Operation, Material Handling, Tool and Equipment Maintenance, Attention to safety Procedures, Support and Assistance",
    ],
    Link: "/jobPlace16",
  },
  {
    id: "17",
    name: "Hybrid",
    mainId: "Bike Rider",
    title: "Bike Rider",
    postedon: "2025-3-10",
    description:
      " A Bike Rider is responsible for transporting goods, food, or passengers from one location to another efficiently and safely. They must navigate traffic, follow delivery schedules, and ensure timely deliveries while maintaining the quality and safety of the items they transport. Bike riders may work for courier services, food delivery companies, e-commerce businesses, or as personal transport service providers.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior level",
    salary: "Market competitive salary",
    color: "outdoor",
    size: "juniorlevel",
    projectType: "Hybrid",
    skills: [
      "Riding skills, Navigation & Route planning, Time management, Customer Service, Physical stamina, Basic maintenance knowledge, Traffic awareness, Cash handling & digital payments",
    ],
    Link: "/jobPlace17",
  },
  {
    id: "18",
    name: "Onsite",
    mainId: "Sales Manager",
    title: "Sales Manager",
    postedon: "2025-3-10",
    description:
      "We are looking for a highly motivated and results-driven Sales Manager to lead our sales team, drive revenue growth, and build strong customer relationships. The ideal candidate should have a positive attitude, excellent leadership skills, and a passion for sales success. If you are a goal-oriented professional who thrives in a dynamic environment, we want to hear from you!",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Senior Level ",
    salary: "50K to above",
    color: " Indoor",
    size: "seniorlevel",
    projectType: "Onsite",
    skills: [
      "Sales & Negotiation skills, Leadership & team management, Communication & Interpersonal skills, Strategic thinking & Problem-sollving, Customer relationship management, Positive attitude & motivation, Technical skills",
    ],
    Link: "/jobPlace18",
  },
  {
    id: "19",
    name: "Onsite",
    mainId: "Executive Management Assistant",
    title: "Executive Management Assistant",
    postedon: "2025-3-10",
    description:
      " We are seeking a highly organized and proactive Executive Management Assistant to provide comprehensive administrative support to our executive team. In this role, you will manage scheduling, communications, and day-to-day operations, ensuring smooth workflow and effective coordination. Your contributions will allow our leadership to focus on strategic initiatives while you facilitate efficient decision-making and team collaboration.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Senior Level ",
    salary: "50K to above",
    color: " Indoor",
    size: "seniorlevel",
    projectType: "Onsite",
    skills: [
      "Administrative skills, Organizational & Time management, Communication skills, Technical & Computer skills, Problem-solving & Decision-Making, Interpersonal & Teamwork skills, Financial & Budgeting skills.",
    ],
    Link: "/jobPlace19",
  },
  {
    id: "20",
    name: "Onsite",
    mainId: "Shop Keeper",
    title: "Shop Keeper",
    postedon: "2025-3-10",
    description:
      "We are looking for a dedicated and customer-friendly Shopkeeper to manage daily store operations, ensure excellent customer service, and maintain a well-organized sales environment. The ideal candidate should have a positive attitude, good communication skills, and a strong sense of responsibility.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior Level ",
    salary: "30K to above",
    color: " Indoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Customer service skills, Sales & Product noledge, Basic Mathe & Cash handling, Organizational skills, Communication skills, Problem-Sloving abilities, Positive attitude & Reliability.",
    ],
    Link: "/jobPlace20",
  },
  {
    id: "21",
    name: "Onsite",
    mainId: "Computer Operator",
    title: "Computer Operator",
    postedon: "2025-3-10",
    description:
      "We are looking for a skilled and detail-oriented Computer Operator to manage digital tasks in our paper and sticker shop. The ideal candidate should be proficient in computer operations, printing, and basic graphic design, with a positive attitude and strong customer service skills.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Senior Level ",
    salary: "40K to above",
    color: " Indoor",
    size: "seniorlevel",
    projectType: "Onsite",
    skills: [
      "Computer & Printing skills, Basic Graphic design, Attention to detail, Customer service skills, Time management, Problem-solving skills, Data entry & Record-keeping.",
    ],
    Link: "/jobPlace21",
  },
  {
    id: "22",
    name: "Onsite",
    mainId: "Operational staff",
    title: "Operational staff",
    postedon: "2025-3-10",
    description:
      "We are looking for dedicated and hardworking Operational Staff to assist in the daily operations of our paper and sticker shop. The ideal candidate should be organized, detail-oriented, and customer-friendly, ensuring smooth workflow in the shop.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior Level ",
    salary: "45K to above",
    color: " Indoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Customer service skills, Basic machine operation, Organizational skills, Attention to detail, Teamwork & communication, Time management.",
    ],
    Link: "/jobPlace22",
  },
  {
    id: "23",
    name: "Onsite",
    mainId: "Baby Care Worker(Woman)",
    title: "Baby Care Worker(Woman)",
    postedon: "2025-3-10",
    description:
      "We are looking for a caring and experienced Baby Care Worker to provide a safe, nurturing, and engaging environment for infants and young children. The ideal candidate should have 3 to 4 years of experience in child care, along with a warm and patient personality.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior Level ",
    salary: "45K to above",
    color: " Indoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Patience & Compassion, Basic first aid knowledge, Hygiene & Cleanlines, Communication skills, Physical stamina.",
    ],
    Link: "/jobPlace23",
  },
  {
    id: "24",
    name: "Onsite",
    mainId: "Cashier",
    title: "Cashier",
    postedon: "2025-3-10",
    description:
      "We are looking for a reliable and customer-friendly Cashier to handle transactions efficiently and provide excellent service to customers. The ideal candidate should be detail-oriented, trustworthy, and have basic math skills to ensure accurate cash handling and sales processing.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Senior Level ",
    salary: "50K to above",
    color: " Indoor",
    size: "seniorlevel",
    projectType: "Onsite",
    skills: [
      "Basic Maths & Cash handling, Customer service skills, Attention to detail, Time management, Organizational skills, Trustworthiness & Reliability",
    ],
    Link: "/jobPlace24",
  },
  {
    id: "25",
    name: "Hybrid",
    mainId: "Electrician",
    title: "Electrician",
    postedon: "2025-3-10",
    description:
      " We are looking for a skilled and experienced Electrician to install, maintain, and repair electrical systems and equipment. The ideal candidate should have strong technical knowledge, problem-solving skills, and at least 3 years of experience in electrical work.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior Level ",
    salary: "40K to above",
    color: " Hybrid",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Technical knowledge, Problem-solving skills, Safety Awareness, Manual dexterity & physical stamina, Attention to detail, Time management, Communication skills.",
    ],
    Link: "/jobPlace25",
  },
  {
    id: "26",
    name: "Hybrid",
    mainId: "Property Advisor",
    title: "Property Advisor",
    postedon: "2025-3-10",
    description:
      " We are looking for a dynamic and knowledgeable Property Advisor to assist clients in buying, selling, and renting properties. The ideal candidate should have excellent communication skills, market knowledge, and at least 2-3 years of experience in real estate.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior Level ",
    salary: "40K to above",
    color: " Outdoor",
    size: "juniorlevel",
    projectType: "Hybrid",
    skills: [
      "Real estate knowledge, Negotiation skills, Communication & Interpersonal skills, Sales & Marketing skills, Sales & Marketing skills.",
    ],
    Link: "/jobPlace26",
  },
  {
    id: "27",
    name: "Onsite",
    mainId: "Chef(Female)",
    title: "Chef(Female)",
    postedon: "2025-3-10",
    description:
      " We are looking for a talented and experienced Female Chef to prepare delicious and high-quality meals while ensuring a clean and organized kitchen. The ideal candidate should have at least 2 to 3 years of experience in cooking, menu planning, and food safety.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior Level ",
    salary: "40K to above",
    color: " Indoor",
    size: "juniorlevel",
    projectType: "Onsite",
    skills: [
      "Culinary expertise, Food safety & Hygiene, Creativity & Presentation, Time management, Attenetion to detail, Teamwork & Communication, Problem-Solving abilities.",
    ],
    Link: "/jobPlace27",
  },
  {
    id: "28",
    name: "Hybrid",
    mainId: "Labour's",
    title: "Labour's",
    postedon: "2025-3-10",
    description:
      " We are looking for hardworking and physically fit Laborers to assist in various tasks, including loading, unloading, material handling, and general shop or site maintenance. The ideal candidate should be reliable, willing to work in a team, and capable of performing physically demanding tasks.",
    location: " Chauburji",
    company: "Career Grow",
    jobType: "Full-time",
    experience: "Junior Level ",
    salary: "35K to above",
    color: " Outdoor",
    size: "juniorlevel",
    projectType: "Hybrid",
    skills: [
      "Physical stamina & strength, Teamwork & cooperation, Basic understanging of safety protocols, Hardworking & reliable, Time management.",
    ],
    Link: "/jobPlace28",
  },
];
export default JobData;
