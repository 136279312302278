import React from 'react'
import Layout from '../Components/Layout'
// import Aos from 'aos';
import Parallex from '../Components/Parallex';
import WhatsappButton from '../Components/WhatsappButton';
import { Link } from 'react-router-dom';


const About = () => {
  return (
    <Layout>
     <WhatsappButton/>
         <div>
        <img src='./assets/about.png' style={{width:"100%"}} alt='img'/>
        </div>
      
  {/* About Start */}
<div className="container-fluid py-5">
  <div className="container px-lg-5">
    <div className="row g-5">
      <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
        <div className="section-title position-relative mb-4 pb-2">
          <h6 className="position-relative  ps-2 fs-5" style={{color:"#32A852"}}>About Us</h6>
          <h4 className="mt-2 text-light">Career Grow: Elevate Your Career Seamless Job Applications</h4>
        </div>
        <p className="mb-4 text-light">Embark on a transformative journey with Career Grow, where job opportunities converge with insightful blogs. Apply for your dream job and explore a wealth of career wisdom to shape your professional success</p>
        <div className="row g-3">
          <div className="col-sm-6">
            <h6 className="mb-3 text-light"><i className="fa fa-check text-success me-2" />Award Winning</h6>
            <h6 className="mb-0 text-light"><i className="fa fa-check text-success me-2" />Professional Team</h6>
          </div>
          <div className="col-sm-6">
            <h6 className="mb-3 text-light"><i className="fa fa-check text-success me-2" />24/7 Support</h6>
            <h6 className="mb-0 text-light"><i className="fa fa-check text-success me-2" />Daily Jobs </h6>
          </div>
        </div>
        
      </div>
      <div className="col-lg-6">
        <img className="img-fluid wow zoomIn" data-wow-delay="0.5s" src="./assets/pic.png" style={{height:"300px" , width:"500px"}} alt='img'/>
      </div>
    </div>
  </div>
</div>
{/* About End */}

  <Parallex/>

  {/*Main layout*/}
  <main className="mt-5">
    <div className="container">
      {/*Section: Content*/}
      <section>
        <div className="row my-5">
          <div className="col-md-6 gx-5 mb-4">
            <div className="bg-image hover-overlay shadow-2-strong" data-mdb-ripple-init data-mdb-ripple-color="light">
              <img src="./assets/3.png" className="img-fluid" style={{height:"400px", width:"500px"}} alt='img'/>
              <Link to="/">
                <div className="mask" style={{backgroundColor: 'rgba(251, 251, 251, 0.15)'}} />
              </Link>
            </div>
          </div>
          <div className="col-md-6 gx-5 mb-4 text-light">
            <h3><strong>Our People</strong></h3>
            <p className="">
            At Career Grow, our mission is to help people get jobs. We have more than ~13,000 global employees passionately pursuing this purpose and improving the recruitment journey through real stories and data. We foster a collaborative workplace that strives to create the best experience for job seekers
            </p>
         
          </div>
        </div>
      </section>
     
      <section>
        <div className="row my-5">
       
          <div className="col-md-6 gx-5 mb-4 text-light">
            <h3><strong>Our Leadership</strong></h3>
            <p className="">
            Career Grow's leadership team is diverse, dedicated, and committed to empowering our employees to fulfill our mission. By fostering strong partnerships and collaboration, they serve and support job seekers, employers, society, and our employees.
            </p>
         
          </div>
          <div className="col-md-6 gx-5 mb-4">
            <div className="bg-image hover-overlay shadow-2-strong" data-mdb-ripple-init data-mdb-ripple-color="light">
              <img src="./assets/4.png" className="img-fluid" style={{height:"400px", width:"500px"}} alt='img'/>
              <Link to="/">
                <div className="mask" style={{backgroundColor: 'rgba(251, 251, 251, 0.15)'}} />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row my-5">
          <div className="col-md-6 gx-5 mb-4">
            <div className="bg-image hover-overlay shadow-2-strong" data-mdb-ripple-init data-mdb-ripple-color="light">
              <img src="./assets/1.png" className="img-fluid" style={{height:"400px", width:"500px"}} alt='img'/>
              <Link to="/">
                <div className="mask" style={{backgroundColor: 'rgba(251, 251, 251, 0.15)'}} />
              </Link>
            </div>
          </div>
          <div className="col-md-6 gx-5 mb-4 text-light">
            <h3><strong>Our Commitments</strong></h3>
            <p className="">
            We are committed to making a positive impact on society by connecting people to better work to create better lives. With the power of our platform and our people, and in partnership with organizations that share our values, we aim to bring about a future of work that is equitable and inclusive.
            </p>
         
          </div>
        </div>
      </section>

    </div>
  </main>
  {/*Main layout*/}
    </Layout>
  )
}

export default About
