import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./JobCard.css";

function Product({ product }) {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(
    () => {
      const updateTime = () => {
        const now = dayjs();
        const postedTime = dayjs(product.postedon);
        const diffInSeconds = now.diff(postedTime, "second");

        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const remainingSeconds = diffInSeconds % 60;
        const diffInHours = Math.floor(diffInMinutes / 60);
        const remainingMinutes = diffInMinutes % 60;
        const diffInDays = Math.floor(diffInHours / 24);
        const remainingHours = diffInHours % 24;

        if (diffInDays > 0) {
          setTimeAgo(
            `${diffInDays} day${diffInDays > 1
              ? "s"
              : ""} ${remainingHours} hour${remainingHours > 1 ? "s" : ""} ago`
          );
        } else if (diffInHours > 0) {
          setTimeAgo(
            `${diffInHours} hour${diffInHours > 1
              ? "s"
              : ""} ${remainingMinutes} min ago`
          );
        } else if (diffInMinutes > 0) {
          setTimeAgo(`${diffInMinutes} min ${remainingSeconds} sec ago`);
        } else {
          setTimeAgo(`${remainingSeconds} sec ago`);
        }
      };

      updateTime();
      const intervalId = setInterval(updateTime, 1000);

      return () => clearInterval(intervalId);
    },
    [product.postedon]
  );

  return (
    <div className="container-fluid">
      <div className="container jobcard">
        <div className="row innerjob d-flex align-items-center">
          {/* Left Section (Job Details) */}
          <div className="col-md-8 text-start">
            <h1 className="fs-3 fw-bold">
              {" "}
              {product.title} ~ {product.company}
            </h1>
            <p className="fs-5">
              &#x2022; {product.jobType} &#x2022; {product.experience} &#x2022;{" "}
              {product.projectType}
            </p>
            <h5
              className="text-light p-2"
              style={{ width: "150px", backgroundColor: "#32A852" }}
            >
              {product.salary}
            </h5>
            <div className="d-flex align-items-start ">
              <h4 className="me-2 mt-2">Skills:</h4>
              <p className="mt-2">{`${product.skills.slice(0, 10)}...`}</p>
            </div>
          </div>

          {/* Right Section (Apply Now Button) */}
          <div className="col-md-3 text-end">
            <Link to={product.Link}>
              <button type="button" className="btn btn-sm mb-3" id="button">
                Apply Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
